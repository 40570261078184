import * as React from "react"

export function LinkedIn(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <g fill="#1A2639" fillOpacity={0.8}>
        <path d="M3.606.026a3.606 3.606 0 100 7.209A3.606 3.606 0 007.21 3.63 3.606 3.606 0 003.606.026zM6.713 9.97H.496v20.004h6.217V9.97zM22.542 9.472c-3.025 0-5.053 1.659-5.883 3.231h-.083V9.97h-5.963v20.004h6.212v-9.896c0-2.61.496-5.136 3.731-5.136 3.188 0 3.23 2.983 3.23 5.303v9.729H30V19c0-5.386-1.162-9.529-7.458-9.529z" />
      </g>
    </svg>
  )
}
