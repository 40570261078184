import * as React from "react"

export function Gmail(props) {
  return (
    <svg width={30} height={30} viewBox="0 0 30 30" fill="none" {...props}>
      <path
        d="M28.883 4.278H1.117C.5 4.278 0 4.778 0 5.395v19.21c0 .617.5 1.117 1.117 1.117h27.766c.617 0 1.117-.5 1.117-1.117V5.395c0-.617-.5-1.117-1.117-1.117zM27.88 22.981a.938.938 0 01-.947.927h-1.557V9.257l-9.674 6.28a1.288 1.288 0 01-1.404 0l-9.674-6.28v14.652H3.067a.938.938 0 01-.947-.927V7.019c0-.512.424-.928.947-.928h1.284l10.65 6.914L25.648 6.09h1.284c.523 0 .947.416.947.928v15.962z"
        fill="#1A2639"
        fillOpacity={0.8}
      />
    </svg>
  )
}
